<template>
  <div>
    <v-card
      class="mb-0 mb-sm-10 mb-md-10 mb-lg-10 mb-xl-10 mt-0 pa-3"
      color="blue darken-4"
      dark
    >
      <v-list-item
        v-if="formId !==wsFormId"
        three-line
      >
        <v-list-item-content>
          <div
            class="text-overline mb-4 text--white"
          >
            {{ userInfo.office ? userInfo.office : '未填写部门' }}
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ userInfo.nickname }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ userInfo.motto ? userInfo.motto : '未填写职位' }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          tile
          size="80"
        >
          <v-avatar
            size="80"
          >
            <img
              :src="userInfo.avatar ? userInfo.avatar : userPhoto"
            >
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item
        v-else
        three-line
      >
        <v-list-item-content>
          <v-list-item-title
            style="color:#fff;font-size:14px"
          >
            项目提供联系人
          </v-list-item-title>
          <div
            style="color:#fff;font-size:14px"
          >
            项目信息来源: <span
              style="color:#fff;font-size:14px"
              class="ml-2"
            >
              {{ userInfo.nickname }}
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions
        v-if="userlevel !== 'county'"
      >
        <v-btn
          outlined
          @click="userSelectDialog"
        >
          <v-icon
            left
            small
          >
            fa fa-random
          </v-icon>
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="userSelect"
      max-width="1000px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>点击组织机构后选择管理单位</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="userSelect = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="4">
              <div class="userTree">
                <v-treeview
                  v-model="userGroup"
                  :items="userGroupTree"
                  activatable
                  rounded
                  open-all
                  @update:active="selectOrg"
                />
              </div>
            </v-col>
            <v-col cols="8">
              <v-alert
                v-if="!usersCard"
                type="info"
              >
                点击左侧选择组织机构后，显示管理单位。
              </v-alert>
              <div
                v-if="usersCard"
                class="userTree"
              >
                <v-card
                  v-for="(user) in userList"
                  :key="user.id"
                  class="mx-auto mb-3"
                  outlined
                  dark
                  color="blue darken-4"
                >
                  <v-card-actions>
                    <v-list-item class="grow">
                      <v-list-item-avatar color="grey darken-3">
                        <v-img
                          class="elevation-6"
                          :src="user.avatar ? user.avatar : userPhoto"
                        />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ user.nickname }}</v-list-item-title>
                      </v-list-item-content>

                      <v-row
                        align="center"
                        justify="end"
                      >
                        <v-btn
                          color="success"
                          rounded
                          small
                          @click="changeWaiter(user.id)"
                        >
                          <v-icon
                            left
                            small
                          >
                            fa fa-check
                          </v-icon>
                          选择
                        </v-btn>
                      </v-row>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import userPhoto from '../../../assets/user_photo.jpg'
  import jsonTree from '../../../utils/tree'
  export default {
    props: {
      userId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
      formId: {
        type: String,
        default: null,
      },
      btnText: {
        type: String,
        default: '项目管理单位变更',
      },
      dialogTitle: {
        type: String,
        default: '请勾选组织机构后，点选管理单位',
      },
    },
    data () {
      return {
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        usersCard: false,
        userInfo: {},
        userPhoto: userPhoto,
        userSelect: false,
        userGroup: [],
        userGroupTree: [],
        userList: [],
        userlevel: false,
        orgId: '',
      }
    },
    watch: {
      'userId' () {
        this.getUserInfo()
      },
    },
    async created () {
      // 获取用户信息
      if (this.userId) {
        this.getUserInfo()
      }
      this.getMeInfo()
      await this.getUserGroupTree()
    },
    methods: {
      userSelectDialog () {
        this.userSelect = true
      },
      // 获取自己所属的orgId
      getMeInfo () {
        this.$axios.get('/me')
          .then(res => {
            this.orgId = res.data.data.orgId
          })
      },
      //! 获取服务专员的信息
      getUserInfo () {
        this.$axios.get(`/users/${this.userId}/show`)
          .then(res => {
            this.userInfo = res.data.data
          })
      },
      //! 获取用户组的树形结构
      async getUserGroupTree () {
        // 获取当前用户的等级
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        this.userGroupTree = jsonTree(resData, {
          id: 'id',
          pid: 'parentId',
          children: 'children',
        })
      },
      // 格式化用户组数据返回树形结构
      treeData (source, id) {
        const cloneData = JSON.parse(JSON.stringify(source))
        // console.log(cloneData)
        return cloneData.filter(father => {
          const branchArr = cloneData.filter(child => father.id === child.parentId)
          branchArr.length > 0 ? father.children = branchArr : father.children = []
          return father.id === id
        })
      },
      //! 选择用户组
      async selectOrg (val) {
        if (val.length > 0) {
          this.usersCard = true
          await this.$axios.get(`/groups/${val[0]}/users`)
            .then(res => {
              this.userList = res.data.data
            })
        } else {
          this.usersCard = false
        }
      },
      //! 改变服务专员
      changeWaiter (id) {
        this.$axios.put(`/reports/${this.reportId}/transfer`, {
          userId: id,
          message: '变更项目管理单位',
        })
          .then(res => {
            this.$swal({
              title: '变更成功。',
              icon: 'success',
              showCancelButton: false,
            })
            this.userId = id
            this.getUserInfo()
            this.userSelect = false
          })
          .catch(err => {
            this.$swal({
              title: err.response.data.msg,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: '确定',
            })
          })
      },
    },
  }
</script>
<style scoped>
.userTree{
  max-height: 550px;
  overflow: auto;
}
</style>
